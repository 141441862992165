export default class Usuario {
  constructor(
    id = null,
    nome = null,
    username = null,
    secret = null,
    keycloak_id = null,
    email = null,
    telefone = null,
    ativo = true,
    consignatarias = [],
    perfis = [],
    grupos = [],
    roles = [],
  ) {
    this.id = id
    this.nome = nome
    this.username = username
    this.secret = secret
    this.keycloak_id = keycloak_id
    this.email = email
    this.telefone = telefone
    this.ativo = ativo
    this.consignatarias = consignatarias
    this.perfis = perfis
    this.grupos = grupos
    this.roles = roles
  }
}
