<template>
  <div>
    <Card>
      <template #title>Usuários</template>
      <template #content>
        <div class="flex mb-3">
          <form class="flex-grow-1" @submit.prevent="pesquisarUsuario">
            <div class="flex flex-wrap">
              <div class="col-12 md:col-6 p-0 md:flex">
                <div class="entrada w-full mr-2">
                  <InputText
                    id="usuarioInput"
                    v-model="usuarioPesquisa"
                    class="w-full"
                    type="text"
                    placeholder="CPF, Nome ou E-mail"
                    autofocus
                    @keydown.enter="pesquisarUsuario" />
                </div>
                <div class="botoes mt-2 md:mt-0 flex justify-content-center">
                  <Button
                    id="btn-limparBuscarServidor"
                    class="p-button-outlined mr-2"
                    icon="pi pi-filter-slash"
                    label="Limpar"
                    @click="limparPesquisa" />
                  <Button
                    id="btn-buscarServidor"
                    :icon="loading ? 'pi pi-spin pi-spinner' : 'pi pi-search'"
                    :label="loading ? 'Carregando...' : 'Buscar'"
                    @click="pesquisarUsuario()" />
                </div>
              </div>
              <div
                class="col-12 md:col-6 mt-2 md:mt-0 p-0 flex justify-content-center md:justify-content-end">
                <Button
                  label="Novo Usuário"
                  icon="pi pi-plus"
                  @click="abrirNovoUsuario(null)" />
              </div>
            </div>
          </form>
        </div>
        <DataTable
          class="p-datatable-sm"
          :value="usuarios"
          :paginator="true"
          :rows="15"
          :rowsPerPageOptions="[10, 15, 20]"
          :loading="loading"
          sortField="nome"
          :sortOrder="1"
          :filters.sync="filtros"
          filterDisplay="menu"
          :globalFilterFields="['nome', 'email', 'username']"
          dataKey="id"
          stripedRows
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          responsiveLayout="scroll">
          <template #empty> Nenhuma usuário encontrado. </template>
          <template #loading> Carregando. Por favor aguarde. </template>

          <Column field="username" header="CPF" />
          <Column field="nome" header="Nome" sortable />
          <Column field="email" header="Email" />
          <Column header="Ações">
            <template #body="slotProps">
              <div class="flex">
                <Button
                  v-tooltip.left="'Editar'"
                  icon="pi pi-pencil"
                  class="p-button-rounded p-button-primary mr-2"
                  @click="editarUsuario(slotProps)" />
                <Button
                  v-show="slotProps.data.email"
                  v-tooltip.left="'Gerar nova senha por email'"
                  icon="pi pi-key"
                  class="p-button-rounded p-button-warning mr-2"
                  @click="gerarNovaSenha(slotProps.data)" />

                <Button
                  v-if="$auth.user.uuid != slotProps.data.keycloakId"
                  v-tooltip.left="'Permissões'"
                  icon="pi pi-lock-open"
                  class="p-button-rounded p-button-danger mr-2"
                  @click="permissoes(slotProps.data)" />
              </div>
            </template>
          </Column>
        </DataTable>
      </template>
    </Card>

    <Dialog
      :visible.sync="usuarioDialog"
      header="Novo Usuário"
      :closeOnEscape="!saving && !confirmDialog"
      :closable="!saving"
      :modal="true"
      class="p-fluid">
      <BlockUI :blocked="saving">
        <Card>
          <template #content>
            <div
              v-if="!usuarioAtivoLoading && usuario.id"
              class="inline-block col-6 md:col-6">
              <div class="field-checkbox">
                <Checkbox
                  id="ativo"
                  v-model="usuarioAtivo"
                  name="ativo"
                  :binary="true" />
                <label class="mt-2" for="ativo">Ativo</label>
              </div>
            </div>
            <div v-if="usuarioAtivoLoading" class="">
              <span
                v-if="usuarioAtivoLoading"
                class="pi pi-spin pi-spinner"></span>
            </div>
            <div class="block">
              <div class="inline-block col-6 md:col-6">
                <label for="nome" class="required">Nome</label>
                <InputText id="nome" v-model="v$.usuario.nome.$model" />
                <div
                  v-if="submitted && v$.usuario.nome.required.$invalid"
                  class="p-error">
                  O campo Nome é obrigatório.
                </div>
              </div>
              <div class="inline-block col-6 md:col-6">
                <label for="mail" class="required">E-mail</label>
                <InputText v-model="usuario.email" />
                <div
                  v-if="submitted && v$.usuario.email.required.$invalid"
                  class="p-error">
                  O campo E-mail é obrigatório.
                </div>
                <div
                  v-if="submitted && v$.usuario.email.email.$invalid"
                  class="p-error">
                  E-mail é inválido.
                </div>
              </div>
            </div>
            <div class="inline-block col-6 md:col-6">
              <label for="cpf" class="required">CPF</label>
              <InputMask
                id="cpf"
                v-model="usuario.username"
                :disabled="usuario.id"
                :unmask="true"
                mask="999.999.999-99" />
              <div
                v-if="submitted && v$.usuario.username.required.$invalid"
                class="p-error">
                O campo CPF é obrigatório.
              </div>
            </div>
            <div class="inline-block col-6 md:col-6">
              <label for="telefone" class="required">Telefone</label>
              <InputMask
                id="telefone"
                v-model.trim="usuario.telefone"
                mask="(99)99999-9999" />
              <div
                v-if="submitted && v$.usuario.telefone.required.$invalid"
                class="p-error">
                O campo Telefone é obrigatório.
              </div>
            </div>

            <div class="inline-block col-12 md:col-12">
              <div
                v-if="submitted && v$.usuario.consignatarias.required.$invalid"
                class="p-error">
                Selecione pelo menos uma consignatária.
              </div>
              <DataTable
                :value="consignatarias"
                :loading="loadingConsignatarias"
                :selection.sync="consignatariasSelecionadas"
                responsiveLayout="scroll"
                dataKey="id">
                <Column
                  selectionMode="multiple"
                  :headerStyle="{ width: '3em' }"></Column>
                <Column field="razaoSocial" header="Razão Social"></Column>
                <Column field="nomeFantasia" header="Nome Fantasia"></Column>
              </DataTable>
            </div>
          </template>
        </Card>
      </BlockUI>
      <template #footer>
        <Button
          :disabled="saving"
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-text"
          @click="fecharNovoUsuario" />
        <Button :disabled="saving" autofocus @click="salvarUsuario">
          <span v-if="!saving" class="pi pi-check"></span>
          <span v-if="saving" class="pi pi-spin pi-spinner"></span>
          <span v-if="!saving" class="ml-2">Salvar</span>
          <span v-if="saving" class="ml-2">Aguarde</span>
        </Button>
      </template>
    </Dialog>
    <Dialog
      :visible.sync="permissoesDialog"
      :closeOnEscape="false"
      header="Permissões"
      :closable="true"
      :modal="true"
      class="p-fluid">
      <h4>{{ usuario.nome }}</h4>
      <form class="mr-5 ml-5">
        <div v-show="!loading" class="fadein animation-duration-200">
          <div class="field-checkbox">
            <!-- <Tag v-if="roleativo.ativo" value="Usuário ATIVO" severity="success" icon="pi pi-check" />
            <Tag v-if="!roleativo.ativo" value="Usuário DESATIVADO" severity="danger" icon="pi pi-times" /> -->
            <Checkbox
              v-show="!roleativo.loading"
              :id="roleativo.role"
              v-model="roleativo.ativo"
              :binary="true"
              @change="alterarAtivo()" />
            <i
              v-show="roleativo.loading"
              class="pi pi-spin pi-spinner"
              style="font-size: 1.5rem"
              aria-hidden="true" />
            <label class="mb-0">{{ roleativo.label }}</label>
          </div>

          <div class="field-checkbox">
            <Checkbox
              v-show="!roleadmin.loading"
              :id="roleadmin.role"
              v-model="roleadmin.ativo"
              :binary="true"
              @change="alterarMaster()" />
            <i
              v-show="roleadmin.loading"
              class="pi pi-spin pi-spinner"
              style="font-size: 1.5rem"
              aria-hidden="true" />
            <label class="mb-0">{{ roleadmin.label }}</label>
          </div>

          <div
            v-for="item in roles"
            :key="item.id"
            class="field-checkbox fadein animation-duration-200">
            <Checkbox
              v-show="!item.loading"
              :id="item.role"
              v-model="item.ativo"
              :binary="true"
              :disabled="roleadmin.ativo"
              @change="alterarRole(item)" />
            <i
              v-show="item.loading"
              class="pi pi-spin pi-spinner"
              style="font-size: 1.5rem"
              aria-hidden="true" />
            <label
              :for="item.role"
              class="mb-0"
              :class="roleadmin.ativo ? 'text-300' : 'text-color'"
              >{{ item.label }}</label
            >
          </div>
        </div>

        <div v-if="loading" class="flex justify-content-center flex-wrap m-4">
          <ProgressSpinner style="width: 50px; height: 50px" />
        </div>
      </form>

      <template #footer>
        <Button
          label="Fechar"
          icon="pi pi-times"
          class="p-button-text"
          @click="permissoesDialog = false" />
      </template>
    </Dialog>
  </div>
</template>

<script>
import UseVuelidate from '@vuelidate/core'
import { FilterMatchMode } from 'primevue/api'
import { required, email, minLength } from '@vuelidate/validators'
import UsuarioService from '@/service/UsuarioService'
import Usuario from '@/domain/Usuario'

export default {
  setup() {
    return { v$: UseVuelidate() }
  },

  data() {
    return {
      permissoesDialog: false,
      usuarioDialog: false,
      confirmDialog: false,
      loading: false,
      loadingConsignatarias: false,
      saving: false,
      submitted: false,
      usuarios: null,
      consignatarias: null,
      consignatariasSelecionadas: null,
      usuario: new Usuario(),
      usuarioAtivo: false,
      usuarioAtivoLoading: false,
      filtros: {},
      roleadmin: {
        role: 'CONSIGNATÁRIA-ADMIN',
        label: 'USUÁRIO MASTER',
        ativo: false,
        loading: false,
      },
      roleativo: {
        role: 'CONSIGNATÁRIA-USER',
        label: 'ATIVO',
        ativo: false,
        loading: false,
      },
      roles: [
        { id: 0, role: 'AVERBAR', label: 'AVERBAR', ativo: false },
        {
          id: 1,
          role: 'CANCELAR SOLICITAÇÃO DE COMPRA DE DÍVIDA',
          label: 'CANCELAR SOLICITAÇÃO DE COMPRA DE DÍVIDA',
          ativo: false,
        },
        {
          id: 2,
          role: 'CANCELAR-SUSPENDER CONSIGNAÇÕES',
          label: 'CANCELAR-SUSPENDER CONSIGNAÇÕES',
          ativo: false,
        },
        {
          id: 3,
          role: 'CANCELAR-SUSPENDER RESERVA DE CARTÃO',
          label: 'CANCELAR-SUSPENDER RESERVA DE CARTÃO',
          ativo: false,
        },
        {
          id: 4,
          role: 'CONFIRMAR COMPRA DE DÍVIDA',
          label: 'CONFIRMAR COMPRA DE DÍVIDA',
          ativo: false,
        },
        {
          id: 5,
          role: 'FAZER RESERVA DE CARTÃO',
          label: 'FAZER RESERVA DE CARTÃO',
          ativo: false,
        },
        {
          id: 6,
          role: 'IMPORTAR ARQUIVO DE CARTÃO',
          label: 'IMPORTAR ARQUIVO DE CARTÃO',
          ativo: false,
        },
        {
          id: 7,
          role: 'IMPORTAR ARQUIVO DE REAJUSTE DE MENSALIDADE',
          label: 'IMPORTAR ARQUIVO DE REAJUSTE DE MENSALIDADE',
          ativo: false,
        },
        {
          id: 8,
          role: 'RENEGOCIAR CONSIGNAÇÕES',
          label: 'RENEGOCIAR CONSIGNAÇÕES',
          ativo: false,
        },
        {
          id: 9,
          role: 'SOLICITAR COMPRA DE DÍVIDA',
          label: 'SOLICITAR COMPRA DE DÍVIDA',
          ativo: false,
        },
        {
          id: 10,
          role: 'VISUALIZAR RELATÓRIOS',
          label: 'VISUALIZAR RELATÓRIOS',
          ativo: false,
        },
      ],
      usuarioPesquisa: '',
    }
  },

  validations() {
    return {
      usuario: {
        nome: { required },
        username: { required },
        email: { required, email },
        telefone: { required },
        consignatarias: { required, minLength: minLength(1) },
      },
    }
  },

  created() {
    this.service = new UsuarioService(this.$http)
    this.usuario = new Usuario()
    this.iniciarFiltro()
  },

  mounted() {
    this.loading = true
    this.service.getListaUsuariosConsignatariaLogada().then((res) => {
      this.usuarios = res
      this.loading = false
    })
  },

  methods: {
    validate() {
      this.submitted = true
      this.v$.usuario.$touch()
      return !this.v$.usuario.$invalid
    },

    pesquisarUsuario() {
      this.filtros['global'].value = this.usuarioPesquisa
    },

    iniciarFiltro() {
      this.filtros = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      }
    },

    limparPesquisa() {
      this.usuarioPesquisa = ''
      this.iniciarFiltro()
    },

    getListaPermissoes() {},

    abrirNovoUsuario() {
      this.saving = false
      this.submitted = false
      this.v$.usuario.$reset()
      this.usuarioDialog = true
      this.usuario = new Usuario()
      this.consignatariasSelecionadas = null
      this.getListaConsignatarias()
    },

    isUsuarioAtivo() {
      this.usuarioAtivoLoading = true
      console.log(this.usuario)
      this.service
        .hasAcessoConsignataria(this.usuario.username)
        .then((res) => {
          console.log(res)
          this.usuarioAtivo = res
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.usuarioAtivoLoading = false
        })
    },

    getListaConsignatarias() {
      this.loadingConsignatarias = true
      this.service
        .getListaConsignatariaDoGrupoDaConsignatariaLogada()
        .then((res) => {
          this.consignatarias = res
          this.loadingConsignatarias = false
        })
        .catch((err) => {
          this.loadingConsignatarias = false
          this.$toast.add({
            severity: 'error',
            summary: err.response.data.message,
            life: 10000,
          })
        })
    },

    fecharNovoUsuario() {
      this.saving = false
      this.usuarioDialog = false
      this.loadingConsignatarias = false
    },

    editarUsuario(slotProps) {
      this.saving = false
      this.v$.usuario.$reset()
      this.getListaConsignatarias()
      this.usuario = JSON.parse(JSON.stringify(slotProps.data))
      this.consignatariasSelecionadas = this.usuario.consignatarias
      this.usuarioDialog = true

      this.isUsuarioAtivo()
    },

    gerarNovaSenha(data) {
      this.$confirm.require({
        message:
          'Tem certeza que deseja gerar uma nova senha para ' +
          data.email +
          ' ?',
        header: 'Confirmação',
        acceptLabel: 'SIM',
        rejectLabel: 'NÃO',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.service
            .gerarNovaSenha(data)
            .then(() => {
              this.$toast.add({
                severity: 'success',
                summary: 'Link para gerar nova senha enviada com sucesso!',
                life: 10000,
              })
            })
            .catch((err) => {
              this.$toast.add({
                severity: 'error',
                summary: err.response.data.message,
                life: 10000,
              })
            })
        },
      })
    },

    salvarUsuario() {
      this.usuario.consignatarias = this.consignatariasSelecionadas
      if (this.validate()) {
        this.saving = true
        this.service
          .saveUsuarioConsignataria(this.usuario, this.usuarioAtivo)
          .then((res) => {
            this.fecharNovoUsuario()
            if (this.usuario.id) {
              const index = this.usuarios.findIndex(
                (element) => element.id === this.usuario.id,
              )
              this.$set(this.usuarios, index, res.data)
              this.$toast.add({
                severity: 'success',
                summary: 'Usuário atualizado com sucesso.',
                life: 10000,
              })
            } else {
              this.usuarios.push(res.data)
              this.$toast.add({
                severity: 'success',
                summary: 'Usuário cadastrado com sucesso.',
                life: 10000,
              })
            }
          })
          .catch((err) => {
            this.$toast.add({
              severity: 'error',
              summary: err.response.data.message,
              life: 10000,
            })
            this.saving = false
          })
      }
    },

    alterarRole(item) {
      this.$set(this.roles[item.id], 'loading', true)
      let permissao = {
        usuario: this.usuario,
        role: item.role,
        ativo: item.ativo,
      }
      this.service
        .alterarPermissao(permissao)
        .then(() => {
          this.roles[item.id].loading = false
        })
        .catch(() => {
          this.roles[item.id].loading = false
        })
    },

    alterarAtivo() {
      this.roleativo.loading = true
      let permissao = {
        usuario: this.usuario,
        role: this.roleativo.role,
        ativo: this.roleativo.ativo,
      }
      this.service
        .alterarPermissao(permissao)
        .then(() => {
          this.atualizarListaPermisao(this.usuario)
        })
        .catch(() => {})
        .finally(() => {
          this.roleativo.loading = false
        })
    },

    alterarMaster() {
      this.roleadmin.loading = true
      let permissao = {
        usuario: this.usuario,
        role: this.roleadmin.role,
        ativo: this.roleadmin.ativo,
      }

      this.roles.forEach((role) => {
        role.ativo = this.roleadmin.ativo
        this.$set(role, 'loading', true)
      })
      this.service
        .alterarPermissao(permissao)
        .then(() => {
          this.atualizarListaPermisao(this.usuario)
        })
        .catch(() => {
          this.roleadmin.loading = false
        })
    },

    atualizarListaPermisao(usuario) {
      this.service
        .getListaRolesUsuario(usuario)
        .then((res) => {
          this.clearRoles()
          this.roleadmin.loading = false
          this.roles.forEach((role) => {
            role.ativo = false
            this.$set(role, 'loading', false)
          })
          res.forEach((role) => {
            const index = this.roles.findIndex(
              (element) => element.role === role.name,
            )
            if (index > -1) {
              this.roles[index].ativo = true
            }
          })

          const index = res.findIndex(
            (element) => element.name === this.roleadmin.role,
          )
          if (index >= 0) {
            this.roleadmin.ativo = true
            this.roles.forEach((role) => {
              role.ativo = this.roleadmin.ativo
            })
          }

          const indexAtivo = res.findIndex(
            (element) => element.name === this.roleativo.role,
          )

          if (indexAtivo >= 0) {
            this.roleativo.ativo = true
          } else {
            this.roleativo.ativo = false
          }

          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },

    permissoes(data) {
      this.clearRoles()
      this.usuario = data
      this.permissoesDialog = true
      this.loading = true
      this.atualizarListaPermisao(data)
    },

    clearRoles() {
      this.roleadmin.ativo = false
      this.roleativo.ativo = false
      this.roles.forEach((el) => {
        el.ativo = false
      })
    },
  },
}
</script>
<style scoped>
.required:after {
  content: ' *';
  color: red;
}

.p-card {
  box-shadow: none;
}
::v-deep .p-dialog .p-card .p-card-body {
  padding: 0rem !important;
}
</style>
